import React, { useEffect, useState } from 'react';
import { FaUser, FaBoxOpen, FaMapMarkerAlt, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccountPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [role, setRole] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            const token = localStorage.getItem('token');
            console.log('Fetched Token:', token);

            if (!token) {
                console.log('No token found, redirecting to login.');
                toast.error("No token found. Redirecting to login.");
                navigate('/login');
                return;
            }

            try {
                console.log('Attempting to fetch user details...');
                const userResponse = await axios.get('https://backend.yeniesuq.com/api/user', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (userResponse.data && userResponse.data.success) {
                    setUserDetails(userResponse.data.data);
                    setRole('user');
                } else {
                    // Attempt to fetch seller details if user details are not found
                    const sellerResponse = await axios.get('https://backend.yeniesuq.com/api/seller', {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (sellerResponse.data && sellerResponse.data.success) {
                        setUserDetails(sellerResponse.data.data);
                        setRole('seller');
                    } else {
                        throw new Error('Failed to fetch user or seller details.');
                    }
                }
            } catch (err) {
                console.error('Error fetching user details:', err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data.message : err.message);
                toast.error('Failed to fetch user details. Please try logging in again.');
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        toast.info("Logged out successfully.");
        navigate('/login');
    };

    const handleMyOrdersClick = () => {
        navigate('/orders');  // Navigate to the orders page
    };

    if (loading) {
        return <div style={{ color: 'blue', fontSize: '20px' }}>Loading user details...</div>;
    }

    if (error) {
        return <div style={{ color: 'red', fontSize: '20px' }}>Error: {error}</div>;
    }

    if (!userDetails) {
        return <div style={{ color: 'orange', fontSize: '20px' }}>No user details to display.</div>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-green-50 to-yellow-50 p-9">
            <div className="bg-white rounded-lg shadow-md p-6 mb-6 flex items-center space-x-4">
                <div className="w-16 h-16 rounded-full bg-green-200 flex items-center justify-center text-3xl text-green-600">
                    <FaUser />
                </div>
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800">
                        {userDetails?.name || "User"}
                    </h2>
                    <p className="text-gray-500">{userDetails?.email || "Email not provided"}</p>
                    <p className="text-gray-400 text-sm">
                        {role === 'seller' ? "Seller Account" : "User Account"}
                    </p>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-4 space-y-4">
                <OptionRow 
                    icon={<FaBoxOpen />} 
                    title="My Orders" 
                    description="View order history" 
                    onClick={handleMyOrdersClick} // Added onClick to navigate
                />
                {role === 'seller' && (
                    <OptionRow icon={<FaBoxOpen />} title="My Products" description="Manage your products" />
                )}
                <OptionRow icon={<FaMapMarkerAlt />} title="My Addresses" description="Manage delivery addresses" />
                <OptionRow icon={<FaCog />} title="Settings" description="Update account settings" />
                <OptionRow 
                    icon={<FaSignOutAlt />} 
                    title="Logout" 
                    description="Sign out from your account" 
                    onClick={handleLogout} 
                />
            </div>

            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
        </div>
    );
};

const OptionRow = ({ icon, title, description, onClick }) => (
    <div
        className="flex items-center justify-between p-4 hover:bg-gray-100 rounded-lg transition-all duration-200 cursor-pointer"
        onClick={onClick}
    >
        <div className="flex items-center space-x-4">
            <div className="text-green-600 text-xl">{icon}</div>
            <div>
                <h3 className="text-lg font-medium text-gray-800">{title}</h3>
                <p className="text-sm text-gray-500">{description}</p>
            </div>
        </div>
        <span className="text-gray-400 text-lg">&gt;</span>
    </div>
);

export default AccountPage;
