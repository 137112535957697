import React, { useState } from 'react';
import { FaUpload } from 'react-icons/fa';
import axios from 'axios';
import Select from 'react-select';

const categoryOptions = [
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Clothing', label: 'Clothing' },
    { value: 'Books', label: 'Books' },
    { value: 'Home & Kitchen', label: 'Home & Kitchen' },
];

const subcategoryOptions = {
    Electronics: [
        { value: 'Mobile', label: 'Mobile' },
        { value: 'Laptop', label: 'Laptop' },
        { value: 'Camera', label: 'Camera' },
    ],
    Clothing: [
        { value: 'Men', label: 'Men' },
        { value: 'Women', label: 'Women' },
        { value: 'Kids', label: 'Kids' },
    ],
};

const AddProductPage = () => {
    const [formData, setFormData] = useState({
        title: '',
        sku: '',
        color: '',
        size: '',
        brand: '',
        price: '',
        image: null,
        description: '',
        quantity: 30,
        seller_email: '',
        status: 'Available',
    });
    const [category, setCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
    };

    const handleCategoryChange = (selectedCategory) => {
        setCategory(selectedCategory);
        setSubcategories([]);
    };

    const handleSubcategoryChange = (selectedSubcategories) => {
        setSubcategories(selectedSubcategories);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        const productData = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            productData.append(key, value);
        });
        productData.append('category', category ? category.value : '');
        productData.append('subcategory', subcategories.map((sub) => sub.value).join(', '));

        try {
            const response = await axios.post('https://backend.yeniesuq.com/api/products', productData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.data.success) {
                setMessage('Product added successfully!');
                resetForm();
            } else {
                setMessage(response.data.message || 'Failed to add product');
            }
        } catch (error) {
            setMessage('Error adding product. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setFormData({
            title: '',
            sku: '',
            color: '',
            size: '',
            brand: '',
            price: '',
            image: null,
            description: '',
            quantity: 30,
            seller_email: '',
            status: 'Available',
        });
        setCategory(null);
        setSubcategories([]);
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-green-50 to-yellow-50 px-4 pt-16 pb-20">
            <div className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
                <h2 className="text-2xl font-semibold text-green-700 mb-4 text-center">Add New Product</h2>
                {message && <p className="text-center text-yellow-600 mb-4">{message}</p>}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <InputField
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        placeholder="Product Title"
                        required
                    />
                    <InputField
                        name="sku"
                        value={formData.sku}
                        onChange={handleChange}
                        placeholder="SKU"
                        required
                    />
                    <InputField
                        name="color"
                        value={formData.color}
                        onChange={handleChange}
                        placeholder="Color"
                        required
                    />
                    <InputField
                        name="size"
                        value={formData.size}
                        onChange={handleChange}
                        placeholder="Size"
                        required
                    />
                    <InputField
                        name="brand"
                        value={formData.brand}
                        onChange={handleChange}
                        placeholder="Brand"
                        required
                    />
                    <InputField
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        placeholder="Price"
                        required
                    />

                    {/* Category Dropdown */}
                    <Select
                        options={categoryOptions}
                        value={category}
                        onChange={handleCategoryChange}
                        placeholder="Select Category"
                        className="w-full"
                        isClearable
                    />

                    {/* Subcategory Multi-select */}
                    <Select
                        options={subcategoryOptions[category?.value] || []}
                        value={subcategories}
                        onChange={handleSubcategoryChange}
                        placeholder="Select Subcategories"
                        className="w-full"
                        isMulti
                        isClearable
                        isDisabled={!category}
                    />

                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Product Description"
                        rows="3"
                        className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                    />

                    {/* Fancy Image Upload */}
                    <FancyImageUpload
                        imageFile={formData.image}
                        onImageChange={handleImageChange}
                    />

                    <button
                        type="submit"
                        className={`w-full p-3 rounded-lg text-white ${loading ? 'bg-gray-400' : 'bg-green-600 hover:bg-green-700'} transition duration-300`}
                        disabled={loading}
                    >
                        {loading ? 'Adding Product...' : 'Add Product'}
                    </button>
                </form>
            </div>
        </div>
    );
};

const InputField = ({ name, value, onChange, placeholder, required }) => (
    <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500"
    />
);

const FancyImageUpload = ({ imageFile, onImageChange }) => {
    return (
        <div className="border border-gray-300 rounded-lg p-4 flex flex-col items-center space-y-2">
            <label htmlFor="image-upload" className="cursor-pointer flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-green-500 rounded-lg hover:bg-green-100 transition">
                {imageFile ? (
                    <img
                        src={URL.createObjectURL(imageFile)}
                        alt="Product Preview"
                        className="w-full h-full object-cover rounded-md mb-2"
                    />
                ) : (
                    <>
                        <FaUpload className="text-green-500 mb-2" size={30} />
                        <span className="text-gray-700">Click or Drag to Upload Image</span>
                    </>
                )}
                <input
                    type="file"
                    id="image-upload"
                    onChange={onImageChange}
                    accept="image/*"
                    className="hidden"
                />
            </label>
        </div>
    );
};

export default AddProductPage;
