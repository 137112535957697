import React, { useState, useEffect } from 'react';
import logo from './logo.png';  // Import logo.png
import './preloader.css';  // Import preloader styles

const Preloader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate page load or any data fetching process
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide the preloader after a set time
      document.body.classList.add('loaded'); // Add 'loaded' class for fade-out effect
    }, 3000); // Adjust as needed for your loading time

    return () => clearTimeout(timer); // Cleanup the timer when component unmounts
  }, []);

  return (
    isLoading && (
      <div className="preloader-wrapper">
        <div className="preloader-content">
          <div className="spinner">
            <img
              src={logo}  // Use the imported logo here
              alt="Yene Suq Logo"
              className="preloader-logo"
            />
          </div>
          <p className="preloader-text">Please Wait...</p>
        </div>
      </div>
    )
  );
};

export default Preloader;
