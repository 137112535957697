import React, { useState } from 'react';
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom'; // Import Link for navigation
import 'react-toastify/dist/ReactToastify.css';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Dynamically set API route based on role
        const apiRoute = role === 'seller' ? '/api/seller/login' : '/api/user/login';

        try {
            const response = await axios.post(`https://backend.yeniesuq.com${apiRoute}`, { email, password });
            setLoading(false);

            if (response.data.success) {
                toast.success('Login successful!');

                // Store the token in local storage
                localStorage.setItem('token', response.data.token); // Adjust based on your API response structure

                // Role-based dashboard redirection
                setTimeout(() => {
                    navigate('/account'); // Redirect to account page
                }, 500); // Short delay for UX
            } else {
                toast.error(response.data.message || 'Login failed. Please try again.');
            }
        } catch (err) {
            setLoading(false);
            const errorMessage = err.response?.data?.message || 'Unable to login. Please try again later.';
            toast.error(errorMessage);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-green-200 to-green-500">
            <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-2xl rounded-lg transform transition-all duration-300 hover:scale-105">
                <h2 className="text-3xl font-bold text-center text-green-700">Welcome Back</h2>
                <p className="text-center text-gray-600 mb-4">Login to access your account</p>
                <form onSubmit={handleLogin} className="space-y-6">
                    <div className="relative">
                        <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email"
                            className="w-full p-3 pl-10 text-gray-700 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
                        />
                    </div>
                    <div className="relative">
                        <FaLock className="absolute top-3 left-3 text-gray-400" />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Password"
                            className="w-full p-3 pl-10 text-gray-700 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
                        />
                    </div>
                    <div className="relative">
                        <FaUser className="absolute top-3 left-3 text-gray-400" />
                        <select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="w-full p-3 pl-10 text-gray-700 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
                        >
                            <option value="user">User</option>
                            <option value="seller">Seller</option>
                        </select>
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full p-3 text-white rounded-full transition-all duration-300 transform ${
                            loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700 hover:scale-105'
                        } focus:outline-none focus:ring-2 focus:ring-yellow-500`}
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>

                {/* Sign Up Button */}
                <p className="text-center text-gray-600 mt-4">
                    Don't have an account?{' '}
                    <Link
                        to="/signup"
                        className="text-green-700 font-bold underline hover:text-green-800"
                    >
                        Sign Up
                    </Link>
                </p>

                <ToastContainer
                    position="top-center"
                    style={{ marginTop: '60px' }} // Adjust this value to place Toastify notifications lower
                    autoClose={3000}
                    hideProgressBar
                />
            </div>
        </div>
    );
};

export default LoginPage;
