import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

const Checkout = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [servicePayment, setServicePayment] = useState(75); // Fixed service fee
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userId, setUserId] = useState(null);
  const [guestId, setGuestId] = useState("");

  const API_URL = "https://backend.yeniesuq.com/api/checkout/create"; // Replace with your backend API endpoint

  // Load cart and user data from localStorage once during the initial render
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCart);

    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      let storedGuestId = localStorage.getItem("guestId");

      // If no guest ID exists, generate one and store it
      if (!storedGuestId) {
        storedGuestId = `guest-${Math.random().toString(36).substr(2, 9)}`; // Generate a unique guest ID
        localStorage.setItem("guestId", storedGuestId); // Save it in localStorage
      }

      setGuestId(storedGuestId);
    }
  }, []); // Empty dependency array ensures this only runs once

  // Memoize totalPrice to optimize re-calculation
  useMemo(() => {
    const subtotal = cartItems.reduce(
      (acc, item) => acc + (Number(item.price) || 0) * item.quantity,
      0
    );
    setTotalPrice(subtotal + servicePayment); // Add service fee to the total price
  }, [cartItems, servicePayment]); // Only recalculated when cartItems or servicePayment change

  // Validate and handle the checkout process
  const handleCheckout = async () => {
    setErrorMessage("");
    setSuccessMessage("");
  
    // Check if all required fields are filled
    if (
      !customerName ||
      !customerEmail ||
      !customerPhone ||
      !shippingAddress ||
      cartItems.length === 0 ||
      totalPrice <= 0
    ) {
      setErrorMessage(
        "All fields are required, and the cart must not be empty with a valid total price."
      );
      return;
    }
  
    // Prepare checkout data
    const checkoutData = {
      customer_name: customerName,
      customer_email: customerEmail,
      customer_phone: customerPhone,
      shipping_address: shippingAddress,
      total_price: totalPrice,
      cartItems: cartItems.map((item) => ({
        product_id: item.id, // Assuming 'id' is your product_id
        quantity: item.quantity,
        price: item.price,
      })),
      guest_id: guestId, // Send guest ID if available
      user_id: userId || null, // Use userId if logged in, otherwise null
    };
  
    try {
      setIsLoading(true);
      const response = await axios.post(API_URL, checkoutData);
  
      if (response.status === 201) {
        // Assuming backend returns checkout_id and guest_id after successful checkout
        const { checkout_id, guest_id } = response.data;
  
        // Store individual pieces of checkout data in localStorage
        localStorage.setItem("checkout_id", checkout_id); // Save checkout_id
        localStorage.setItem("customer_name", customerName);
        localStorage.setItem("customer_email", customerEmail);
        localStorage.setItem("customer_phone", customerPhone);
        localStorage.setItem("shipping_address", shippingAddress);
        localStorage.setItem("total_price", totalPrice.toString());
        localStorage.setItem("cart_items", JSON.stringify(cartItems));
        localStorage.setItem("guest_id", guest_id || guestId); // Use the new guest ID or the existing one
        localStorage.setItem("user_id", userId || "null");
  
        setSuccessMessage("Checkout successful! Redirecting...");
        localStorage.removeItem("cart"); // Clear cart data from localStorage after checkout
        setTimeout(() => (window.location.href = "/payment"), 2000); // Redirect to payment page after successful checkout
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Checkout failed. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="checkout-container max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md mt-10 h-[90vh] flex flex-col justify-between">
      <h2 className="text-2xl font-bold text-green-600 text-center mb-6">Checkout</h2>

      <div className="flex flex-col flex-grow overflow-y-auto pb-12">
        {/* Cart Items */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-green-600">Your Cart</h3>
          <ul className="text-sm">
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <li key={item.id} className="flex justify-between py-1 border-b">
                  <span>{item.title} x {item.quantity}</span>
                  <span>{(Number(item.price) || 0).toFixed(2)} birr</span>
                </li>
              ))
            ) : (
              <li className="text-red-500">No items in the cart</li>
            )}
          </ul>
          <div className="font-semibold text-green-700 mt-4">
            Total Price: {totalPrice.toFixed(2)} birr
          </div>
        </div>

        {/* Shipping Address */}
        <div className="mb-6">
          <label htmlFor="shippingAddress" className="block text-green-600">
            Shipping Address
          </label>
          <input
            type="text"
            id="shippingAddress"
            name="shippingAddress"
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            value={shippingAddress}
            onChange={(e) => setShippingAddress(e.target.value)}
            placeholder="Enter shipping address"
          />
        </div>

        {/* Customer Information */}
        <div className="mb-6">
          <label htmlFor="customerName" className="block text-green-600">
            Customer Name
          </label>
          <input
            type="text"
            id="customerName"
            name="customerName"
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            placeholder="Enter your name"
          />
          <label htmlFor="customerEmail" className="block text-green-600 mt-4">
            Customer Email
          </label>
          <input
            type="email"
            id="customerEmail"
            name="customerEmail"
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            placeholder="Enter your email"
          />
          <label htmlFor="customerPhone" className="block text-green-600 mt-4">
            Customer Phone
          </label>
          <input
            type="text"
            id="customerPhone"
            name="customerPhone"
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            placeholder="Enter your phone number"
          />
        </div>

        {/* Error or Success Message */}
        {errorMessage && <div className="text-red-500 text-center">{errorMessage}</div>}
        {successMessage && <div className="text-green-500 text-center">{successMessage}</div>}
      </div>

      {/* Checkout Button */}
      <button
        onClick={handleCheckout}
        className="w-full py-2 mt-1 bg-green-600 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
        disabled={isLoading}
      >
        {isLoading ? "Processing..." : "Proceed to Payment"}
      </button>
    </div>
  );
};

export default Checkout;
