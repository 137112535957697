import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Product from './Product';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewArrivals = () => {
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const slideRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://backend.yeniesuq.com/api/products');
        if (Array.isArray(response.data)) {
          setProducts(response.data);
        } else if (response.data.products && Array.isArray(response.data.products)) {
          const parsedProducts = response.data.products.map((product) => {
            const imageArray = JSON.parse(product.image.replace(/'/g, '"'));
            const imageUrl = `https://admin.yeniesuq.com/assets/images/${imageArray[0]}`;
            return { ...product, imageUrl };
          });
          setProducts(parsedProducts);
        } else {
          console.error('Unexpected response format:', response.data);
          setProducts([]);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    startAutoSlide();

    return () => clearInterval(intervalRef.current);
  }, []);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        (prevIndex + 1) % Math.ceil(products.length / 2)
      );
    }, 3000);
  };

  const stopAutoSlide = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.scrollTo({
        left: currentIndex * slideRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  }, [currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + Math.ceil(products.length / 2)) % Math.ceil(products.length / 2)
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % Math.ceil(products.length / 2)
    );
  };

  const handleAddToCart = (product) => {
    // Create the new item to be added
    const newItem = {
      id: product.id,
      title: product.title,
      price: product.price,
      quantity: 1,
      image: JSON.stringify(product.images), // Assuming images are an array
    };

    // Retrieve existing cart from local storage
    const existingCart = JSON.parse(localStorage.getItem('cart')) || [];

    // Check if the product already exists in the cart
    const productExists = existingCart.some(item => item.id === newItem.id);

    if (productExists) {
      // Show a toast notification
      toast.info(`${product.title} is already in the cart.`);
    } else {
      // Add the new item to the cart
      existingCart.push(newItem);
      // Save back to local storage
      localStorage.setItem('cart', JSON.stringify(existingCart));
      // Show a success toast notification
      toast.success(`${product.title} has been added to the cart!`);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-green-500">
        <div className="relative w-16 h-16 border-4 border-yellow-400 border-t-green-500 rounded-full animate-spin"></div>
        <p className="mt-4 text-lg font-medium text-green-500">Loading new arrivals...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-red-500">
        <p>Error loading products: {error.message}</p>
        <button
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={() => window.location.reload()}
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div
      className="bg-green-700 py-1 pb-15"
      onMouseEnter={stopAutoSlide}
      onMouseLeave={startAutoSlide}
    >
      <ToastContainer />
      <h1 className="text-white text-center font-bold text-2xl my-4">New Arrivals</h1>
      <div className="relative">
        <div
          className="flex overflow-hidden space-x-2"
          ref={slideRef}
          style={{ scrollBehavior: 'smooth' }}
        >
          {products.map((product, index) =>
            index % 2 === 0 && (
              <div key={index} className="flex flex-col items-center w-full md:w-1/2 h-64">
                <div className="flex flex-row justify-between items-center w-full h-full p-4 bg-white rounded-lg shadow-md">
                  <Product product={products[index]} onAddToCart={handleAddToCart} />
                  {products[index + 1] && (
                    <Product product={products[index + 1]} onAddToCart={handleAddToCart} />
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white text-green-900 p-2 rounded-full shadow-lg hover:bg-green-200"
          onClick={handlePrev}
          aria-label="Previous Slide"
        >
          &#10094;
        </button>
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white text-green-900 p-2 rounded-full shadow-lg hover:bg-green-200"
          onClick={handleNext}
          aria-label="Next Slide"
        >
          &#10095;
        </button>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: Math.ceil(products.length / 2) }).map((_, index) => (
          <button
            key={index}
            className={`h-2 w-2 rounded-full mx-1 ${currentIndex === index ? 'bg-green-900' : 'bg-gray-300'}`}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default NewArrivals;
