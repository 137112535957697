import React, { useState, useEffect } from "react";
import "keen-slider/keen-slider.min.css"; // Import styles for Keen Slider
import { useKeenSlider } from "keen-slider/react";

const VegetableTipsSlider = () => {
  // Keen Slider setup with sync for active slide
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    mode: "free-snap",
    slides: {
      perView: 1,
      spacing: 15,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  // Vegetable tips data
  const tips = [
    { id: 1, text: "Proudly sponsored by Nice Innovation and Incubation Center and Kefta Microfinance, empowering growth and success." },
    { id: 2, text: "Nice Innovation and Incubation Center supports innovation, providing opportunities for growth and development." },
    { id: 3, text: "Kefta Microfinance is committed to enhancing financial inclusion and empowering communities." },
    { id: 4, text: "Together, Nice Innovation and Incubation Center and Kefta Microfinance provide the tools for your success." },
    { id: 5, text: "Thank you to our sponsors, Nice Innovation and Incubation Center and Kefta Microfinance, for making this possible." },
  ];

  // Automatically slide after every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (slider) {
        slider.current?.next(); // Trigger the next slide
      }
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Clear interval when component is unmounted
  }, [slider]);

  return (
    <div className="bg-green-50 py-1 px-1">
      {/* Slider Container */}
      <div ref={sliderRef} className="keen-slider max-w-lg mx-auto">
        {tips.map((tip) => (
          <div
            key={tip.id}
            className="keen-slider__slide bg-white rounded-lg shadow-lg p-8 flex items-center justify-center text-center transition-transform duration-300"
          >
            <p className="text-lg font-semibold text-green-700 leading-relaxed">
              {tip.text}
            </p>
          </div>
        ))}
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center mt-6 space-x-2">
        {tips.map((_, idx) => (
          <div
            key={idx}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentSlide === idx
                ? "bg-yellow-500 scale-125"
                : "bg-yellow-300 hover:scale-110"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default VegetableTipsSlider;
