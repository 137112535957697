import React from 'react';
import Banner from './Banner';
import Categories from './Categories'
import NewArrivals from './New'
import Shop from './Shop'
import VegetableTipsSlider from './Tips';
function Home() {
  return (
    <div>
      <Banner />
    
      <NewArrivals />
      <VegetableTipsSlider />
      <Shop />
    </div>
  );
}

export default Home;
