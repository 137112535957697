import React from 'react';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50">
      <div className="bg-white shadow-lg rounded-lg p-6 text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Payment Canceled</h1>
        <p className="text-lg text-gray-700 mb-4">
          Your payment was not completed. If this was an error, you can try again or contact support.
        </p>
        <div className="mt-6">
          <button
            onClick={() => navigate('/')}
            className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
          >
            Return to Home
          </button>
          <button
            onClick={() => navigate('/checkout')}
            className="ml-4 px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition"
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
