import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Success = () => {
  const location = useLocation(); // To retrieve state passed via navigation (e.g., transactionId)
  const navigate = useNavigate();

  // Retrieve payment details from navigation state
  const { transactionId, message } = location.state || {};

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-50">
      <div className="bg-white shadow-lg rounded-lg p-6 text-center">
        <h1 className="text-3xl font-bold text-green-600 mb-4">Payment Successful!</h1>
        {message && <p className="text-lg text-gray-700 mb-2">{message}</p>}
        {transactionId && (
          <p className="text-sm text-gray-500">
            <strong>Transaction ID:</strong> {transactionId}
          </p>
        )}
        <div className="mt-6">
          <button
            onClick={() => navigate('/')}
            className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
          >
            Go to Home
          </button>
          <button
            onClick={() => navigate('/orders')}
            className="ml-4 px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition"
          >
            View Orders
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
