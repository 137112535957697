import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, UserProvider } from './pages/UserContext'; // Import the UserProvider
import TopNav from './pages/TopNav';
import BottomNav from './pages/BottomNav';
import Categories from './pages/Categories';
import Banner from './pages/Banner';
import Order from'./pages/Orders';
import Home from './pages/Home';
import Cart from './pages/Cart';
import LoginPage from './pages/Login';
import Checkout from './pages/Checkout';
import NewArrivals from './pages/New';
import Shop from './pages/Shop';
import AddProductPage from './pages/upload';
import ProductDetails from './pages/ProductDetails';
import AccountPage from './pages/account';
import Success from './pages/sucess';
import Cancel from './pages/cancel';
import Preloader from './pages/Preloader';
import BouncyLayer from './pages/bouncy';
import Category from './pages/Category';
import Payment from './pages/payment';
import OrderHistory from './pages/Orders';
import VegetableTipsSlider from './pages/Tips'; // Ensure this path is correct
import './index.css'; // Import your global CSS if applicable
import SignUpPage from './pages/Signup';

const App = () => {
  return (
    <Router> {/* Wrap the entire app with Router */}
      <UserProvider> {/* Now inside Router, allowing access to Router context */}
        <div className="App">
          <Preloader />
          
          <TopNav />
         
          {/* Ensure this has enough margin or padding */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Tips" element={<VegetableTipsSlider />}/>
            <Route path="/Orders" element={<OrderHistory />} />
            <Route path="/sucess" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/upload" element={<AddProductPage />} />
            <Route path="/Orders" element={<Order />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/new" element={<NewArrivals />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/category" element={<Category />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/productdetails/:productId" element={<ProductDetails />} />

            {/* Add more routes here as needed */}
          </Routes>
          <BottomNav />
        </div>
      </UserProvider>
    </Router>
  );
};

// Rendering the App component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
