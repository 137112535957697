import React, { useState } from 'react';
import { FaEnvelope, FaLock, FaPhoneAlt, FaUser } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUpPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://backend.yeniesuq.com/api/user/register', {
        name,
        email,
        phone,
        password,
      });

      setLoading(false);
      if (response.data.success) {
        toast.success('Sign up successful!');
        // Redirect to login page or dashboard after successful sign up
        setTimeout(() => {
          window.location.href = '/login'; // Or use navigate('/login') if using react-router
        }, 1000);
      } else {
        toast.error(response.data.message || 'Sign up failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.message || 'Unable to sign up. Please try again later.';
      toast.error(errorMessage);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-green-200 to-green-500">
      <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-2xl rounded-lg transform transition-all duration-300 hover:scale-105">
        <h2 className="text-3xl font-bold text-center text-green-700">Create an Account</h2>
        <p className="text-center text-gray-600 mb-4">Sign up to get started</p>
        <form onSubmit={handleSignUp} className="space-y-6">
          {/* Name Field */}
          <div className="relative">
            <FaUser className="absolute top-3 left-3 text-gray-400" />
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Full Name"
              className="w-full p-3 pl-10 text-gray-700 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
            />
          </div>

          {/* Email Field */}
          <div className="relative">
            <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
              className="w-full p-3 pl-10 text-gray-700 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
            />
          </div>

          {/* Phone Field */}
          <div className="relative">
            <FaPhoneAlt className="absolute top-3 left-3 text-gray-400" />
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              placeholder="Phone Number"
              className="w-full p-3 pl-10 text-gray-700 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
            />
          </div>

          {/* Password Field */}
          <div className="relative">
            <FaLock className="absolute top-3 left-3 text-gray-400" />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
              className="w-full p-3 pl-10 text-gray-700 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full p-3 text-white rounded-full transition-all duration-300 transform ${
              loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700 hover:scale-105'
            } focus:outline-none focus:ring-2 focus:ring-yellow-500`}
          >
            {loading ? 'Signing up...' : 'Sign Up'}
          </button>
        </form>
        <div className="text-center">
          <p className="text-sm text-gray-600">
            Already have an account?{' '}
            <a href="/login" className="text-green-600 hover:underline">
              Login here
            </a>
          </p>
        </div>
        <ToastContainer
          position="top-center"
          style={{ marginTop: '60px' }} // Adjust this value to place Toastify notifications lower
          autoClose={3000}
          hideProgressBar
        />
      </div>
    </div>
  );
};

export default SignUpPage;
