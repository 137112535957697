import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const userEmail = localStorage.getItem('user_email');
        const guestId = localStorage.getItem('guest_id');

        if (!userEmail && !guestId) {
          setError('You must be logged in to view your order history.');
          setLoading(false);
          return;
        }

        const queryParams = userEmail
          ? `?customer_email=${encodeURIComponent(userEmail)}`
          : `?guest_id=${encodeURIComponent(guestId)}`;

        // Make the API call
        const response = await axios.get(`https://backend.yeniesuq.com/api/payments/orders/history${queryParams}`);
        
        if (response.status === 200) {
          console.log('API Response:', response.data); // Check the API response here
          setOrders(response.data.orders);
        } else {
          setError('Failed to fetch order history.');
        }
      } catch (err) {
        setError('Error fetching your order history.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderHistory();
  }, []);

  const downloadReceipt = (orderId) => {
    alert(`Download receipt for Order #${orderId}`);
    // You can implement receipt download here, possibly using the backend endpoint for generating PDFs.
  };

  const openModal = (order) => {
    // Parse the cart_items string to a JavaScript object
    const parsedOrder = {
      ...order,
      cart_items: JSON.parse(order.cart_items) // Parse the cart_items string here
    };
    setSelectedOrder(parsedOrder);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <p className="text-lg text-gray-700">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <p className="text-lg text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white py-9 px-4">
      <h1 className="text-1xl font-semibold text-green-600 text-center mb-8">My Order History</h1>
      {orders.length === 0 ? (
        <p className="text-lg text-gray-700 text-center">No orders found.</p>
      ) : (
        orders.map((order) => (
          <div
            className="order-card bg-yellow-100 border-2 border-yellow-400 rounded-lg p-4 mb-4 shadow-lg cursor-pointer hover:bg-yellow-200 transition"
            key={order.id}
            onClick={() => openModal(order)}
          >
            <h3 className="text-xl font-semibold text-green-700">Order #{order.id}</h3>
            <p className="text-sm text-gray-600"><strong>Date:</strong> {new Date(order.createdAt).toLocaleString()}</p>
            <p className="text-sm text-gray-600"><strong>Payment Method:</strong> {order.payment_method}</p>
            <p className="text-sm text-gray-600"><strong>Total Price:</strong> {order.total_price} ETB</p>

            <button
              className="mt-4 text-blue-600 hover:text-blue-800"
              onClick={() => downloadReceipt(order.id)}
            >
              Download Receipt
            </button>
          </div>
        ))
      )}

      {/* Modal for detailed order information */}
      {selectedOrder && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-full sm:w-96">
            <h2 className="text-2xl font-semibold text-green-600">Order Details - #{selectedOrder.id}</h2>
            <p><strong>Date:</strong> {new Date(selectedOrder.createdAt).toLocaleString()}</p>
            <p><strong>Payment Method:</strong> {selectedOrder.payment_method}</p>
            <p><strong>Payment Status:</strong> {selectedOrder.payment_status}</p>
            <p><strong>Total Price:</strong> {selectedOrder.total_price} ETB</p>
            <p><strong>Shipping Address:</strong> {selectedOrder.shipping_address}</p>

            <h4 className="mt-4 text-lg text-green-700">Items:</h4>
            <ul>
              {Array.isArray(selectedOrder.cart_items) && selectedOrder.cart_items.length > 0 ? (
                selectedOrder.cart_items.map((item) => (
                  <li key={item.id} className="text-gray-700">
                    {item.title} - {item.quantity} x {item.price} ETB
                  </li>
                ))
              ) : (
                <li className="text-gray-700">No items found for this order.</li>
              )}
            </ul>

            <div className="flex justify-between items-center mt-4">
              <button
                className="text-sm text-blue-600 hover:text-blue-800"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
                onClick={() => downloadReceipt(selectedOrder.id)}
              >
                Download Receipt
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
