import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaShoppingCart, FaUser, FaThLarge } from 'react-icons/fa'; // FaThLarge for Category icon

const BottomNav = () => {
  const location = useLocation();
  const [cartCount, setCartCount] = useState(0); // State to hold the cart count

  // Function to update the cart count based on the localStorage cart data
  const updateCartCount = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const count = cart.reduce((total, item) => total + item.quantity, 0); // Calculate total quantity of items
    setCartCount(count);
  };

  useEffect(() => {
    // Initial cart count on component mount
    updateCartCount();

    // Listen for changes in cart data
    const cartInterval = setInterval(() => {
      updateCartCount(); // Update the cart count every second
    }, 1000); // You can adjust this interval to match your use case

    // Clean up the interval on component unmount
    return () => {
      clearInterval(cartInterval);
    };
  }, []);

  const navItems = [
    { name: 'Home', icon: <FaHome />, path: '/' },
    { name: 'Category', icon: <FaThLarge />, path: '/category' },
    { name: 'Cart', icon: <FaShoppingCart />, path: '/cart' },
    { name: 'Account', icon: <FaUser />, path: '/account' },
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 shadow-lg transition-transform duration-300 z-50">
      <div className="flex justify-around items-center h-12 max-w-md mx-auto px-5">
        {navItems.map((item) => (
          <Link
            key={item.name}
            to={item.path}
            aria-label={item.name}
            className={`flex flex-col items-center transition-colors duration-300 relative ${
              location.pathname === item.path
                ? 'text-yellow-500 border-b-2 border-yellow-500'
                : 'text-green-700 hover:text-yellow-500'
            }`}
          >
            <div className="text-2xl mb-1">{item.icon}</div>
            <span className="text-xs font-medium">{item.name}</span>
            {location.pathname === item.path && (
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1 w-1 bg-yellow-500 rounded-full" />
            )}
            {/* Display the cart count when the cart icon is active */}
            {item.name === 'Cart' && cartCount > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1 py-0.5 transform translate-x-1/2 -translate-y-1/2">
                {cartCount}
              </span>
            )}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default BottomNav;
