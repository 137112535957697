import React, { useState, useEffect } from 'react';

const advertisements = [
  {
    img: '/images/b1.jpg', // Use paths relative to the public folder
    title: '',
    description: 'Up to 50% off on all items.',
  },
  {
    img: '/images/b3.jpg',
    title: '',
    description: 'Grab your favorites before they’re gone!',
  },
  {
    img: '/images/b-5.jpg',
    title: ' ',
    description: 'Your number one choice',
  },
];

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % advertisements.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? advertisements.length - 1 : prevIndex - 1
    );
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart !== null && touchEnd !== null) {
      const swipeDistance = touchStart - touchEnd;
      const threshold = 50; // Minimum swipe distance to trigger a change
      if (swipeDistance > threshold) nextSlide();
      if (swipeDistance < -threshold) prevSlide();
    }
    setTouchStart(null);
    setTouchEnd(null);
  };

  useEffect(() => {
    if (!isHovering) {
      const intervalId = setInterval(nextSlide, 3000);
      return () => clearInterval(intervalId);
    }
  }, [isHovering]);

  return (
    <div
      className="relative w-full h-52 md:h-64 lg:h-72 overflow-hidden rounded-lg shadow-lg mb-4"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {advertisements.map((ad, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${
            index === currentIndex ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <img
            src={ad.img}
            className="w-full h-full object-cover"
            alt={`Slide ${index + 1}`}
          />
          <div className="absolute inset-0 flex flex-col justify-center items-center text-center bg-black bg-opacity-50 p-4">
            <h2 className="text-xl font-bold text-white">{ad.title}</h2>
            <p className="text-sm text-white mt-2">{ad.description}</p>
          </div>
        </div>
      ))}

      {/* Navigation Dots */}
      <div className="absolute bottom-0 left-0 w-full flex justify-center space-x-2 mb-2">
        {advertisements.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 md:h-3 md:w-3 rounded-full cursor-pointer transition-all duration-300 ${
              index === currentIndex ? 'bg-yellow-500' : 'bg-gray-300'
            }`}
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>

      {/* Left arrow */}
      <button
        className="absolute left-2 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-80 focus:outline-none"
        onClick={prevSlide}
      >
        &#9664;
      </button>

      {/* Right arrow */}
      <button
        className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-80 focus:outline-none"
        onClick={nextSlide}
      >
        &#9654;
      </button>
    </div>
  );
};

export default Banner;
