import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiLoader, FiAlertTriangle } from 'react-icons/fi';

const Category = () => {
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://backend.yeniesuq.com/api/category');
                console.log('Full API Response:', response);

                if (response.data && response.data.success && Array.isArray(response.data.data)) {
                    setCategories(response.data.data);
                } else {
                    setError('Unexpected data format from API');
                    console.error('Expected data format not found:', response.data);
                }
            } catch (err) {
                setError(`Network Error: ${err.message}`);
                console.error('API fetch error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const selectCategory = (index) => {
        setActiveCategory(index);
    };

    return (
        <div className="flex h-screen bg-gray-50 overflow-hidden">
            {/* Left Sidebar for Categories */}
            <aside className="w-1/3 md:w-1/5 bg-white shadow-md p-3 md:p-9 overflow-y-auto">
                <h2 className="text-lg font-bold text-gray-800 mb-2">Categories</h2>
                <ul className="space-y-2 md:space-y-9">
                    {loading ? (
                        <div className="flex justify-center items-center h-24">
                            <FiLoader className="animate-spin text-gray-500 text-1xl" />
                        </div>
                    ) : error ? (
                        <div className="flex items-center text-red-600">
                            <FiAlertTriangle className="mr-2" />
                            <p>{error}</p>
                        </div>
                    ) : categories.length > 0 ? (
                        categories.map((category, index) => (
                            <li key={index}>
                                <button
                                    onClick={() => selectCategory(index)}
                                    aria-label={`Select ${category.name}`}
                                    className={`block py-3 px-2 rounded-md text-left w-full font-semibold text-sm transition-all duration-300 hover:bg-yellow-100
                                        ${
                                            activeCategory === index
                                                ? 'bg-yellow-200 text-yellow-800'
                                                : 'text-gray-700'
                                        }`}
                                >
                                    {category.name}
                                </button>
                            </li>
                        ))
                    ) : (
                        <p className="text-gray-500">No categories available</p>
                    )}
                </ul>
            </aside>

            {/* Right Section for Subcategory Display */}
            <main className="flex-1 p-4 md:p-9 overflow-y-auto pt-3">
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <FiLoader className="animate-spin text-gray-500 text-3xl" />
                    </div>
                ) : error ? (
                    <div className="flex justify-center items-center h-full text-red-600">
                        <FiAlertTriangle className="mr-2" />
                        <p>{error}</p>
                    </div>
                ) : categories[activeCategory] ? (
                    <>
                        <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 border-b pb-2">
                            {categories[activeCategory].name}
                        </h3>
                        <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                            {categories[activeCategory].subcategories?.map((subcategory, subIndex) => (
                                <div key={subIndex} className="group relative flex flex-col items-center text-center">
                                    <div className="w-12 h-12 bg-gray-200 rounded-full flex justify-center items-center overflow-hidden group-hover:bg-yellow-50 transition-all duration-300">
                                        <img
                                            src={subcategory.image || '/default-placeholder.jpg'}
                                            alt={subcategory.name}
                                            className="rounded-full w-full h-full object-cover"
                                        />
                                    </div>
                                    <span className="mt-1 text-xs md:text-sm text-gray-700 font-medium group-hover:text-yellow-700 transition-all duration-300">
                                        {subcategory.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <p className="text-gray-500 text-center">No subcategories available for this category.</p>
                )}
            </main>
        </div>
    );
};

export default Category;
