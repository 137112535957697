import React, { useEffect, useState } from 'react';
import Product from './Product';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Add to Cart function that saves to local storage
  const addToCart = (product) => {
    const newItem = {
      id: product.id,
      title: product.title,
      price: product.price, // Ensure price is being set correctly
      quantity: 1,
      image: JSON.stringify(product.images), // Assuming images are an array
    };

    // Retrieve existing cart
    const existingCart = JSON.parse(localStorage.getItem('cart')) || [];

    // Check if the product already exists in the cart
    const productExists = existingCart.some(item => item.id === newItem.id);

    if (productExists) {
      // Show a toast notification
      toast.info(`${product.title} is already in the cart.`);
    } else {
      // Add the new item to the cart
      existingCart.push(newItem);
      // Save back to local storage
      localStorage.setItem('cart', JSON.stringify(existingCart));
      // Show a success toast notification
      toast.success(`${product.title} has been added to the cart!`);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://backend.yeniesuq.com/api/products');
        if (!response.ok) throw new Error('Failed to fetch products.');
        const data = await response.json();

        if (!Array.isArray(data.products)) throw new Error('Invalid product data.');

        const parsedProducts = data.products.map((product) => {
          // Try to parse the image array, handle any potential errors
          let imageUrl = 'https://via.placeholder.com/150'; // Fallback to placeholder

          try {
            const imageArray = JSON.parse(product.image.replace(/'/g, '"'));
            if (Array.isArray(imageArray) && imageArray.length > 0) {
              imageUrl = `https://admin.yeniesuq.com/assets/images/${imageArray[0]}`;
            }
          } catch (error) {
            console.error("Error parsing image array:", error);
          }

          return { ...product, imageUrl };
        });

        setProducts(parsedProducts);
      } catch (err) {
        setError(!navigator.onLine ? 'No internet connection.' : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-green-500">
        <div className="relative w-16 h-16 border-4 border-yellow-400 border-t-green-500 rounded-full animate-spin"></div>
        <p className="mt-4 text-lg font-medium text-green-500">Loading products...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-red-500">
        <p>{error}</p>
        <button
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={() => window.location.reload()}
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white px-4 pt-1 pb-16">
      <ToastContainer />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {products.map((product) => (
          <Product 
            key={product.id} 
            product={product} 
            onAddToCart={addToCart} // Ensure the function is passed correctly
          />
        ))}
      </div>
    </div>
  );
};

export default Shop;
