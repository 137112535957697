import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setLoading(false);
                return;
            }

            try {
                const userResponse = await axios.get('https://backend.yeniesuq.com/api/user', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (userResponse.data && userResponse.data.success) {
                    setUserDetails(userResponse.data.data);
                    setRole('user');
                } else {
                    const sellerResponse = await axios.get('https://backend.yeniesuq.com/api/seller', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (sellerResponse.data && sellerResponse.data.success) {
                        setUserDetails(sellerResponse.data.data);
                        setRole('seller');
                    } else {
                        throw new Error('Failed to fetch user or seller details.');
                    }
                }
            } catch (err) {
                setError(err.response ? err.response.data.message : err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, []);

    return (
        <UserContext.Provider value={{ userDetails, role, loading, error }}>
            {children}
        </UserContext.Provider>
    );
};
