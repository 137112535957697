import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiLoader, FiAlertTriangle } from 'react-icons/fi';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://backend.yeniesuq.com/api/category');
        if (response.data && response.data.success && Array.isArray(response.data.data)) {
          setCategories(response.data.data);
        } else {
          setError('Unexpected API response format.');
          console.error('API data format error:', response.data);
        }
      } catch (err) {
        setError(`Network error: ${err.message}`);
        console.error('API fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="bg-white shadow-md p-4 rounded-md py-3">
      <h2 className="text-lg font-bold text-gray-800 mb-3">Categories</h2>
      <div className="flex overflow-x-auto hide-scrollbar pb-5">
        {loading ? (
          <div className="flex justify-center items-center w-full h-20">
            <FiLoader className="animate-spin text-gray-500 text-2xl" />
          </div>
        ) : error ? (
          <div className="flex justify-center items-center text-red-600">
            <FiAlertTriangle className="mr-2" />
            <p>{error}</p>
          </div>
        ) : categories.length > 0 ? (
          categories.map((category) => (
            <div
              key={category.name}
              className="flex-shrink-0 w-20 sm:w-24 flex flex-col items-center justify-center px-2 py-2 mx-1 text-gray-800 border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 hover:shadow-lg transition-all"
              title={category.description || category.name} // Tooltip on hover
            >
              <div className="w-16 h-16 sm:w-20 sm:h-20 rounded-full overflow-hidden border border-gray-200">
                <img
                  src={category.image || '/default-placeholder.jpg'} // Fallback to a default image
                  alt={category.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <span className="text-sm text-center mt-2 truncate">{category.name}</span>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No categories available</p>
        )}
      </div>
    </div>
  );
};

export default Categories;
