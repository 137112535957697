import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState('Bank Transfer');
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [guestId, setGuestId] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingAddress, setShippingAddress] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const guestId = localStorage.getItem('guest_id') || '';
      const cartItems = JSON.parse(localStorage.getItem('cart_items') || '[]');
      const totalPrice = Number(localStorage.getItem('total_price') || 0);
      const shippingAddress = localStorage.getItem('shipping_address') || '';
      const customerName = localStorage.getItem('customer_name') || '';
      const customerEmail = localStorage.getItem('customer_email') || '';
      const customerPhone = localStorage.getItem('customer_phone') || '';

      setGuestId(guestId);
      setCartItems(cartItems);
      setTotalPrice(totalPrice);
      setShippingAddress(shippingAddress);
      setCustomerName(customerName);
      setCustomerEmail(customerEmail);
      setCustomerPhone(customerPhone);
    } catch (error) {
      setError('Error loading data from localStorage.');
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 2 * 1024 * 1024) {
      setError('File size must be less than 2MB.');
      return;
    }
    if (file && !file.type.startsWith('image')) {
      setError('Please upload a valid image file.');
      return;
    }
    setPaymentScreenshot(file);
    setError('');
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setMessage('Account number copied to clipboard!');
    setTimeout(() => setMessage(''), 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!paymentScreenshot) {
      setError('Please upload the payment screenshot.');
      return;
    }

    if (!guestId || !cartItems.length || !totalPrice || !shippingAddress || !customerName || !customerEmail || !customerPhone) {
      setError('All fields are required. Please ensure all data is filled.');
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    const formData = new FormData();
    formData.append('guest_id', guestId);
    formData.append('payment_method', paymentMethod);
    formData.append('payment_screenshot', paymentScreenshot);
    formData.append('cart_items', JSON.stringify(cartItems));
    formData.append('total_price', totalPrice);
    formData.append('shipping_address', shippingAddress);
    formData.append('customer_name', customerName);
    formData.append('customer_email', customerEmail);
    formData.append('customer_phone', customerPhone);

    try {
      const response = await fetch('https://backend.yeniesuq.com/api/payments/create', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        setSuccessModalVisible(true);
        setPaymentScreenshot(null);
      } else {
        setError(data.message || 'Error submitting payment.');
      }
    } catch (err) {
      setError('Failed to submit payment. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const closeSuccessModal = () => {
    setSuccessModalVisible(false);
    navigate('/orders');
  };

  return (
    <div className="max-w-lg mx-auto mt-10 p-12 bg-white border rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center text-yellow-500 mb-6">Submit Payment</h2>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      {message && <p className="text-green-500 text-center mb-4">{message}</p>}

      {/* Display Total Price */}
      <div className="mb-2 text-center bg-gray-100 p-2 rounded-lg shadow-sm">
        <h3 className="text-lg font-semibold text-gray-700">Total Price</h3>
        <p className="text-1xl font-bold text-green-600">{totalPrice.toFixed(2)} ብር</p>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Bank Account Details */}
        <div className="mb-6 bg-green-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-green-700 mb-4">Bank Account Information</h3>
          {[
            { name: 'Coop Bank', account: '1071600050484' },
            { name: 'Dashen Bank', account: '0781727897011' },
            { name: 'BOA', account: '128666858' },
            { name: 'CBE', account: '1000482255788' },
            { name: 'Ahadu Bank', account: '0016183710101' },
          ].map((bank, index) => (
            <div key={index} className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium">
                {bank.name}: <span className="font-bold">{bank.account}</span>
              </span>
              <button
                type="button"
                className="text-green-600 hover:text-green-800"
                onClick={() => handleCopy(bank.account)}
              >
                <FaCopy className="inline-block mr-1" /> Copy
              </button>
            </div>
          ))}
        </div>

        {/* Payment Method Selection */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="payment_method">
            Payment Method
          </label>
          <select
            id="payment_method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          >
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Chapa">Chapa (coming soon)</option>
            <option value="TeleBirr">TeleBirr (coming soon)</option>
            <option value="PayPal">PayPal (coming soon)</option>
          </select>
        </div>

        {/* File Upload */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="payment_screenshot">
            Upload Payment Screenshot
          </label>
          <div className="mt-1 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg h-24">
            <input
              type="file"
              id="payment_screenshot"
              onChange={handleFileChange}
              className="hidden"
              accept="image/jpeg, image/jpg, image/png"
            />
            <label
              htmlFor="payment_screenshot"
              className="text-green-500 font-medium cursor-pointer hover:underline"
            >
              {paymentScreenshot ? paymentScreenshot.name : 'Click to upload'}
            </label>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className={`px-6 py-2 text-white font-semibold rounded-lg ${loading ? 'bg-gray-400' : 'bg-green-600 hover:bg-green-700'}`}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit Payment'}
          </button>
        </div>
      </form>

      {/* Success Modal */}
      {successModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm text-center">
            <h3 className="text-lg font-semibold text-green-500">Payment Successful!</h3>
            <p className="mt-4 text-sm">Your payment has been successfully submitted. You will be redirected to your orders page shortly.</p>
            <div className="mt-4">
              <button
                onClick={closeSuccessModal}
                className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-md"
              >
                Go to Orders
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
