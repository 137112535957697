import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaHeart, FaComment } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comment, setComment] = useState('');
  const [commentsList, setCommentsList] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://backend.yeniesuq.com/api/products/${productId}`);
        if (response.data.success) {
          const productData = response.data.product;
          const imageArray = JSON.parse(productData.image.replace(/'/g, '"'));

          setProduct({
            ...productData,
            images: imageArray.map(img => `https://admin.yeniesuq.com/assets/images/${img}`)
          });
        }
      } catch (err) {
        setError('Error fetching product data. Please try again later.');
        console.error("Error fetching product data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  // Handle comment submit
  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (comment.trim()) {
      setCommentsList([...commentsList, comment]);
      setComment('');
    }
  };

  // Handle add to cart with duplicate check
  const handleAddToCart = () => {
    // Check if the product is already in the cart
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const isProductInCart = cartItems.some(item => item._id === product._id); // Check based on product ID or a unique identifier

    if (isProductInCart) {
      toast.error('This product is already in the cart!');
    } else {
      // Add the product to the cart
      cartItems.push(product);
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      toast.success('Added to Cart!');
    }
  };

  if (loading) {
    return <p className="text-center text-gray-600 mt-4">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500 mt-4">{error}</p>;
  }

  if (!product) {
    return <p className="text-center text-red-500 mt-4">Product not found</p>;
  }

  return (
    <div className="p-4 bg-white shadow-lg rounded-lg max-w-md mx-auto mt-6 pb-16 md:max-w-lg lg:max-w-2xl">
      {/* Product Image Carousel */}
      <div className="rounded-lg overflow-hidden mb-4">
        <Carousel
          autoPlay
          infiniteLoop
          showArrows={true}
          showIndicators={true}
          showThumbs={false}
          interval={3000}
          transitionTime={500}
          className="w-full h-64 md:h-80 lg:h-96"
        >
          {product.images && product.images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`${product.title} - Image ${index + 1}`} className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-105" />
            </div>
          ))}
        </Carousel>
      </div>

      {/* Product Info */}
      <div className="mt-4 px-4">
        <h2 className="text-green-800 font-bold text-2xl mb-2 text-center md:text-3xl lg:text-4xl">{product.title}</h2>
        <p className="text-yellow-500 font-bold text-lg mb-1 text-center md:text-xl lg:text-2xl">${product.price}</p>

        {/* Product Description */}
        <p className="text-gray-700 text-sm leading-relaxed mt-2 text-center md:text-base lg:text-lg">
          {product.description}
        </p>

        {/* Like and Comment Count */}
        <div className="flex items-center justify-center mt-4 space-x-4">
          <div className="flex items-center space-x-2">
            <FaHeart className="text-red-500" />
            <span className="text-gray-700 font-semibold">{product.like_count}</span>
          </div>
          <div className="flex items-center space-x-2">
            <FaComment className="text-gray-500" />
            <span className="text-gray-700 font-semibold">{product.comment_count}</span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col space-y-3 mt-6 md:flex-row md:space-y-0 md:space-x-4">
          <button
            className="w-full py-2 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition duration-300 text-center"
            onClick={handleAddToCart}
          >
            Add to Cart
          </button>
          <button
            className="w-full py-2 bg-yellow-500 text-white font-semibold rounded-lg hover:bg-yellow-600 transition duration-300 text-center"
            onClick={() => alert('Redirecting to checkout...')}
          >
            Buy Now
          </button>
        </div>

        {/* Comment Section */}
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Leave a Comment:</h3>
          <form onSubmit={handleCommentSubmit} className="flex flex-col space-y-2">
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Write your comment..."
              className="p-2 border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
            />
            <button
              type="submit"
              className="self-start py-2 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition duration-300"
            >
              Submit Comment
            </button>
          </form>
          {/* Display Comments */}
          <div className="mt-4">
            <h4 className="font-semibold">Comments:</h4>
            <ul className="list-disc pl-5">
              {commentsList.map((c, index) => (
                <li key={index} className="text-gray-700">{c}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Toast Container */}
      <ToastContainer position="top-right" />
    </div>
  );
};

export default ProductDetails;
