import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import logo from './logo.png'; // Adjust the path to your logo
import { useUser } from './UserContext'; // Import the context

const TopNav = () => {
  const location = useLocation(); // Get current location for active state
  const { user } = useUser(); // Get user from context

  // Navigation items array
  const navItems = [
    { name: 'Profile', icon: <FaUser />, path: '/account' },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white border-b border-gray-300 shadow-lg z-50">
      <div className="flex justify-between items-center h-16 max-w-full mx-auto px-6">
        {/* Logo on the left */}
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-8 mr-2" />
        </div>

        {/* Profile link and user name on the right */}
        <div className="flex items-center">
          {user && <span className="text-green-700 mr-4">{user.name}</span>}
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex items-center transition-colors duration-300 relative ${
                location.pathname === item.path
                  ? 'text-yellow-500'
                  : 'text-green-700 hover:text-yellow-500'
              }`}
            >
              <div className="text-2xl mr-2">{item.icon}</div>
              <span className="text-sm">{item.name}</span>
              {location.pathname === item.path && (
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1 w-1 bg-yellow-500 rounded-full" />
              )}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default TopNav;
