import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate(); // Initialize the useNavigate hook for navigation

  // Load cart items from localStorage on component mount
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
  }, []);

  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => {
    const itemPrice = parseFloat(item.price) || 0;
    return total + (itemPrice * item.quantity);
  }, 0);

  // Remove item from cart
  const removeFromCart = (id) => {
    const updatedCart = cartItems.filter(item => item.id !== id);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  // Clear cart
  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cart');
  };

  // Update quantity of item in cart
  const handleQuantityChange = (id, change) => {
    const updatedCart = cartItems.map((item) => {
      if (item.id === id) {
        const newQuantity = item.quantity + change;
        return { ...item, quantity: Math.max(1, newQuantity) };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  // Checkout handler (Navigate to Checkout page)
  const handleCheckout = () => {
    if (cartItems.length > 0) {
      navigate('/checkout'); // Navigate to the Checkout page
    } else {
      alert('Your cart is empty. Please add items before proceeding to checkout.');
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-xl rounded-lg mt-6 border border-gray-300 overflow-y-auto max-h-[80vh] pb-">
      <h2 className="text-2xl font-semibold mb-6 text-green-600 text-center tracking-wide py-4">Your Shopping Cart</h2>

      {cartItems.length === 0 ? (
        <p className="text-gray-500 text-center">Your cart is empty. Add some products to continue shopping!</p>
      ) : (
        <ul className="space-y-6">
          {cartItems.map((item) => (
            <li key={item.id} className="flex items-center justify-between border-b pb-4 mb-6">
              <div className="flex items-center w-full">
                <div className="flex flex-col sm:flex-row w-full">
                  <div className="flex flex-col sm:w-3/4">
                    <h3 className="text-lg font-semibold text-gray-800 leading-tight">{item.title}</h3>
                    <p className="text-xs text-gray-600">Price: <span className="font-bold text-green-600">{parseFloat(item.price).toFixed(2)} birr</span></p>
                    <div className="flex items-center mt-2 space-x-4">
                      <p className="text-sm text-gray-600">Quantity:</p>
                      <button
                        onClick={() => handleQuantityChange(item.id, -1)}
                        className="px-3 py-1 text-lg bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition duration-200"
                        aria-label={`Decrease quantity of ${item.title}`}
                      >
                        -
                      </button>
                      <span className="font-semibold text-lg">{item.quantity}</span>
                      <button
                        onClick={() => handleQuantityChange(item.id, 1)}
                        className="px-3 py-1 text-lg bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition duration-200"
                        aria-label={`Increase quantity of ${item.title}`}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button 
                className="ml-4 text-red-600 hover:text-red-800 transition duration-200"
                onClick={() => removeFromCart(item.id)} 
                aria-label={`Remove ${item.title} from cart`}>
                <FaTrashAlt className="text-xl" />
              </button>
            </li>
          ))}
        </ul>
      )}

      <div className="flex justify-between font-semibold mt-6 text-lg text-green-600">
        <span>Total Price:</span>
        <span>{totalPrice.toFixed(2)} birr</span>
      </div>

      <div className="flex flex-col sm:flex-row sm:space-x-4 mt-6">
        <button 
          className="w-full sm:w-auto px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200 font-semibold shadow-lg mb-4 sm:mb-0"
          onClick={clearCart}>
          Clear Cart
        </button>
        <button 
          className="w-full sm:w-auto px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200 font-semibold shadow-lg"
          onClick={handleCheckout}>
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default Cart;
