import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const Product = ({ product, onAddToCart }) => {
  const isOnSale = product.salePrice && Number(product.salePrice) < Number(product.price);
  const price = Number(product.price);
  const salePrice = product.salePrice ? Number(product.salePrice) : null;

  // Function to render star ratings
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    return (
      <div className="flex items-center">
        {Array.from({ length: 5 }, (_, index) => (
          <span key={index} aria-hidden="true">
            {index < fullStars ? (
              <FaStar className="text-yellow-500" />
            ) : hasHalfStar && index === fullStars ? (
              <FaStarHalfAlt className="text-yellow-500" />
            ) : (
              <FaStar className="text-gray-300" />
            )}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="relative flex flex-col justify-between bg-white rounded-lg shadow-lg p-4 m-1 h-60 md:h-72 transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
      {/* Badge for New or Sale Items */}
      {product.isNew && (
        <span className="bg-green-500 text-white text-xs uppercase font-bold px-2 py-1 rounded-full absolute top-2 left-2 z-10">
          New
        </span>
      )}
      {isOnSale && (
        <span className="bg-yellow-500 text-white text-xs uppercase font-bold px-2 py-1 rounded-full absolute top-2 left-2 z-10">
          Sale
        </span>
      )}

      {/* Add Cart Icon */}
      <button
        onClick={() => onAddToCart({ ...product, quantity: 1 })} // Add with a default quantity of 1
        className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-lg hover:bg-green-200 focus:outline-none z-20 transition duration-200"
        aria-label={`Add ${product.title} to Cart`}
        title={`Add ${product.title} to Cart`}
      >
        <FontAwesomeIcon icon={faCartPlus} className="text-green-500" size="lg" />
      </button>

      {/* Product Image with Link to ProductDetails */}
      <Link to={`/ProductDetails/${product.id}`} className="w-full h-32 md:h-40 overflow-hidden rounded-md">
        <img
          src={product.imageUrl}
          alt={product.title}
          className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-110"
          loading="lazy" // Improve performance by lazy loading images
        />
      </Link>

      {/* Product Details with Title Link */}
      <div className="text-center flex-grow mt-2">
        <Link to={`/ProductDetails/${product.id}`} className="text-green-700 font-semibold text-sm truncate">
          {product.title}
        </Link>

        {/* Star Ratings */}
        <div className="mt-1" aria-label={`Rating: ${product.rating} out of 5`}>
          {renderStars(product.rating)}
        </div>

        {/* Price Display */}
        <div className="text-center mt-1">
          {isOnSale ? (
            <>
              <p className="text-red-500 font-bold text-xs line-through">
                ${price.toFixed(2)}
              </p>
              <p className="text-yellow-500 font-bold text-lg">
                ${salePrice ? salePrice.toFixed(2) : price.toFixed(2)}
              </p>
            </>
          ) : (
            <p className="text-yellow-500 font-semibold text-md">
              ${price.toFixed(2)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
